import './Add.scss'
import { currentUserState } from '../states/currentUserState'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { parse, t } from '../../lib/helper'
import { useEffect, useRef, useState } from 'react'
import * as api from '../../lib/api'
import { useAtom } from 'jotai'
import paywallSites from '../../paywall-sites.json'
export function Add({ setActionMenu } = {}) {
  const [user] = useAtom(currentUserState)
  const [html, setHtml] = useState(null)
  const [isBehindPaywall, setIsBehindPaywall] = useState(false)
  const navigate = useNavigate()
  const location = useLocation()
  const [url, setUrl] = useState(null)
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false)
  const isBehindPaywallChekboxRef = useRef(null)
  const inputUrlRef = useRef(null)
  const [showAdditionalOptions, setShowAdditionalOptions] = useState(false)
  const [availableLanguages, setAvailableLanguages] = useState(null)
  const [availableSummarizer, setAvailableSummarizer] = useState(null)
  const [collections, setCollections] = useState(null)
  const [collectionId, setCollectionId] = useState('auto')
  const [summarizer, setSummarizer] = useState(null)
  const [targetLanguage, setTargetLanguage] = useState(null)
  const [contentCategory, setContentCategory] = useState(null)
  const [contentCategories, setContentCategories] = useState(null)

  setActionMenu(null)

  function handleKeyDown(ev) {
    if (ev.ctrlKey || ev.metaKey) {
      setShowAdditionalOptions(true)
    }
  }

  useEffect(() => {
    if (availableLanguages) {
      return
    }
    async function fetchAvailableLanguages() {
      let data = await api.languages()
      setAvailableLanguages(data.languages)
    }
    async function fetchAvailableSummarizer() {
      let data = await api.summarizer()
      setAvailableSummarizer(data.summarizer)
    }
    async function fetchAvailableCollections() {
      let collections = await api.collections()
      setCollections(collections)
    }
    async function fetchAvailableContentCategories() {
      let data = await api.possibleContentCategories(url)
      setContentCategories(data.categories)
    }
    fetchAvailableLanguages()
    fetchAvailableSummarizer()
    fetchAvailableCollections()
    fetchAvailableContentCategories()
  }, [availableLanguages])

  useEffect(() => {
    let url = new URLSearchParams(location.search).get('url')
    if (url) {
      setUrl(url)
    }
  }, [location])

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown)
    return () => window.removeEventListener('keydown', handleKeyDown)
  }, [])

  useEffect(() => {
    if (!url && !isBehindPaywall.current) {
      return
    }
    try {
      if (
        paywallSites.includes(
          new URL(url).hostname.replace(/^(.+?\.)(.+?\.)(.+)$/, '$2$3')
        )
      ) {
        setIsBehindPaywall(true)
        isBehindPaywallChekboxRef.current.checked = true
      }
    } catch (_) {}
  }, [url, isBehindPaywallChekboxRef])

  async function handleSubmit(ev) {
    ev.preventDefault()
    setSubmitButtonDisabled(true)
    // when pasting text on mobile, changed/keypress events are not fired
    let _url = url || inputUrlRef.current?.value
    if (!_url) {
      return alert(t('Please enter a URL'))
    }
    try {
      let data = await api.addWebsite({
        html,
        url: _url,
        is_behind_paywall: isBehindPaywall,
        language: targetLanguage,
        summarize_mode: summarizer,
        content_classification: contentCategory,
        collection_id: collectionId,
      })
      if (data.website?.id) {
        setTimeout(() => {
          navigate(`/websites/${data.website.share_url}?wait=queue#top`)
          setSubmitButtonDisabled(false)
        }, 800)
      }
    } catch (e) {
      console.error(e)
      let message =
        t('Some problem occured while adding site') +
        ': ' +
        (e.response.data?.message || e.response.data?.error)
      setSubmitButtonDisabled(false)
      alert(
        message +
          '\n\n' +
          [
            t('Please ensure that you have access to the site'),
            t(
              'In most cases that means that you need to be logged in on the website you want to stash'
            ),
            t('Then try again to the save the website with the browser plugin'),
          ].join('. ') +
          '.'
      )
      document.getElementById('send-html').click()
    }
  }

  function handleChangeUrl(ev) {
    setUrl(ev.target.value)
  }

  return (
    <div className="add-website">
      <form className="form" onSubmit={handleSubmit}>
        <fieldset>
          <h2>{t('Add Website')}</h2>
          <input
            type="text"
            required={true}
            placeholder="https://"
            id="url"
            ref={inputUrlRef}
            defaultValue={url}
            onChange={handleChangeUrl}
          ></input>
          <div className="additional-options">
            {!showAdditionalOptions ? (
              <h5 onClick={() => setShowAdditionalOptions(true)}>
                {t('Click here to show all options')}. {t('Or press ctrl / ⌘')}
              </h5>
            ) : (
              <div>
                <div className="form-select">
                  <label htmlFor="collection">
                    {t('Content classification')}:
                  </label>
                  <select
                    onChange={(ev) => setContentCategory(ev.target.value)}
                  >
                    {contentCategories &&
                      Object.entries(contentCategories).map((v) => (
                        <option value={v[0]} key={`content-cat-${v[0]}`}>
                          {v[1]}
                        </option>
                      ))}
                  </select>
                </div>
                <div className="form-select">
                  <label htmlFor="target-language">
                    {t('Target language')}:
                  </label>
                  <select onChange={(ev) => setTargetLanguage(ev.target.value)}>
                    {availableLanguages &&
                      [
                        ...[[null, t('Keep original')]],
                        ...Object.entries(availableLanguages),
                      ].map((lang) => (
                        <option
                          value={lang[0]}
                          key={`lang-${lang[0]}`}
                          selected={lang[0] === user.translate_to_language}
                        >
                          {lang[1]}
                        </option>
                      ))}
                  </select>
                </div>
                <div className="form-select">
                  <label htmlFor="collection">{t('Add to collection')}:</label>
                  <select onChange={(ev) => setCollectionId(ev.target.value)}>
                    <option value="auto">{t('Auto')}</option>
                    {collections &&
                      collections.map((v) => (
                        <option value={v.id} key={`collection-${v.id}`}>
                          {v.name}
                        </option>
                      ))}
                  </select>
                </div>
                {/*<div className="form-select">
                  <label htmlFor="model">{t('Summarize mode')}:</label>
                  <select onChange={(ev) => setSummarizer(ev.target.value)}>
                    {availableSummarizer &&
                      Object.entries(availableSummarizer).map((v) => (
                        <option value={v[0]} key={`summarizer-${v[0]}`}>
                          {v[1]}
                        </option>
                      ))}
                  </select>
                </div> */}
              </div>
            )}
          </div>
          <div className="checkbox">
            <label htmlFor="send-html">
              {t(
                'The website is behind a paywall - or not accessible by third parties'
              )}
            </label>
            <input
              type="checkbox"
              id="send-html"
              defaultValue={isBehindPaywall}
              onChange={(ev) => setIsBehindPaywall(ev.target.checked)}
              ref={isBehindPaywallChekboxRef}
            ></input>
          </div>
          {isBehindPaywall && (
            <>
              <div className="paywall-hint" style={{ marginBottom: '2rem' }}>
                <p>
                  {parse(
                    t(
                      `Hint: It might be easier to use the <a href='https://www.sitestash.net/browser-extension' target='blank'>sitestash-browser-plugin</a> instead of inserting html code manually`
                    )
                  )}{' '}
                  🚀
                </p>
              </div>
              <textarea
                placeholder={t(
                  'Insert the complete html of the page here, so that we can analyze the content without accessing the website'
                )}
                onChange={(ev) => setHtml(ev.target.value)}
                id="html"
                rows={10}
                required={true}
              ></textarea>
            </>
          )}
          <button type="submit" disabled={submitButtonDisabled}>
            {t('Add Website')}
          </button>
        </fieldset>
      </form>
    </div>
  )
}
