import { t } from '../../lib/helper'
import './Collections.scss'
import * as api from '../../lib/api'
import { set } from 'date-fns'
import { searchQueryState } from '../states/searchQueryState'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAtom } from 'jotai'

export function Collections({ setActionMenu } = {}) {
  const [collectionName, setCollectionName] = useState('')
  const [collections, setCollections] = useState(null)
  const navigate = useNavigate()
  const [searchQuery, setSearchQuery] = useAtom(searchQueryState)

  setActionMenu(null)

  async function loadCollections() {
    let collections = await api.collections()
    setCollections(collections || [])
  }

  async function createCollection() {
    if (!collectionName) {
      return
    }
    let data = await api.createCollection(collectionName)
    setCollections([data.collection, ...collections])
  }

  async function handleChangeCollectionName(ev, id) {
    await api.patchCollection(id, { name: ev.target.value })
  }

  async function deleteCollection(id, ev) {
    await api.deleteCollection(id)
    await loadCollections()
  }

  function handleClickOnWebsites(ids) {
    if (!ids?.length) {
      return
    }
    let query = 'id:' + ids.join(',')
    let params = new URLSearchParams({ q: query })
    setSearchQuery(query)
    navigate('/?' + params.toString(), {
      // replace: true,
    })
  }

  useEffect(() => {
    if (!collections) {
      loadCollections()
    }
  }, [collections])

  return (
    <div className="form collections">
      <div className="add-collection">
        <form
          onSubmit={(ev) => {
            ev.preventDefault()
            createCollection()
          }}
        >
          <fieldset>
            <input
              type="text"
              placeholder={t('Name of the collection')}
              maxLength={16}
              onChange={(ev) => setCollectionName(ev.target.value)}
            ></input>
            <button type="submit">{t('Add collection')}</button>
          </fieldset>
        </form>
      </div>
      <div className="collections-list">
        {collections?.map((c) => (
          <div className="collection" key={`collection-${c.id}`}>
            <div
              className="list"
              onClick={() => handleClickOnWebsites(c.websites_ids)}
            >
              {c.websites_ids.length} {t('Websites')}
            </div>
            <input
              defaultValue={c.name}
              placeholder={t('Name of the collection')}
              maxLength={16}
              required={true}
              type="text"
              onChange={(ev) => handleChangeCollectionName(ev, c.id)}
            ></input>{' '}
            <button
              className="small"
              onClick={(ev) => confirm(t('Sure?')) && deleteCollection(c.id)}
            >
              {t('delete')}
            </button>
          </div>
        ))}
      </div>
    </div>
  )
}
