import './Navigation.scss'

import { useEffect, useState } from 'react'
import { currentUserState } from '../states/currentUserState'
import { t } from '../../lib/helper'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import logo from '../../../frontend/img/logo/logo.svg'
import stashBox from '../../../frontend/img/logo/box_empty.svg'
import { useAtom } from 'jotai'

export function Navigation({ actionMenu } = {}) {
  const [user] = useAtom(currentUserState)

  const location = useLocation()
  const navigate = useNavigate()

  function toggleDarkLightTheme() {
    let body = document.querySelector('body')
    let darkModeApplied = body.dataset.theme === 'dark'
    if (darkModeApplied) {
      localStorage.setItem('theme', 'light')
    } else {
      localStorage.setItem('theme', 'dark')
    }
    body.dataset.theme = localStorage.getItem('theme')
  }

  return (
    <header className="collapsed">
      <div className="navigation">
        <div className="logo">
          <Link to={user ? '/' : '/login'}>
            <svg className="text-logo">
              {/* https://svg-to-symbol-app.pages.dev/ */}
              <use href={logo + '#svg-logo'}></use>
            </svg>
            <svg className="box-logo">
              <use href={stashBox + '#svg-box-empty'}></use>
            </svg>
          </Link>
          {user && (
            <Link to="/add">
              <div className="plus">+</div>
            </Link>
          )}
        </div>
        {actionMenu ? <nav className="action-menu">{actionMenu}</nav> : null}
        <nav className="main-menu">
          <ul>
            {user ? (
              <>
                <li key="menu-theme">
                  <div
                    className="a theme"
                    onClick={() => toggleDarkLightTheme()}
                  >
                    {t('Theme')}
                  </div>
                </li>
                <li key="menu-collections">
                  <Link to="/collections">{t('Collections')}</Link>
                </li>
                <li className="profile" key="menu-profile">
                  <Link to="/profile">{t('Your Profile')}</Link>
                </li>
                <li key="menu-add">
                  <Link to="/add">{t('Add Website')}</Link>
                </li>
                {/* <li  key="menu-search">
                  <div className="a search" onClick={() => {
                    setSearchQuery(searchQuery === null || window.scrollY > 0 ? '' : null)
                    window.scrollTo(null, 0);
                    if (location.pathname !== '/') {
                      navigate('/')
                      setSearchQuery('')
                    }
                  }}>{t('Search')}</div>
                </li> */}
              </>
            ) : (
              <>
                <li key="menu-signup">
                  <Link to="/signup">{t('Signup')}</Link>
                </li>
                <li key="menu-login">
                  <Link to="/login">{t('Login')}</Link>
                </li>
              </>
            )}
          </ul>
        </nav>
      </div>
    </header>
  )
}
