{
  "fr": {
    "sitestash": "sitestash",
    "Please enter a URL": "Veuillez saisir une URL",
    "Some problem occured while adding site": "Un problème est survenu lors de l'ajout du site",
    "Please ensure that you have access to the site": "Veuillez vous assurer que vous avez accès au site",
    "In most cases that means that you need to be logged in on the website you want to stash": "Dans la plupart des cas, cela signifie que vous devez être connecté sur le site web que vous souhaitez cacher.",
    "Then try again to the save the website with the browser plugin": "Réessayez ensuite d'enregistrer le site web avec le plugin du navigateur.",
    "Add Website": "Ajouter un site web",
    "Click here to show all options": "Cliquez ici pour afficher toutes les options",
    "Or press ctrl / ⌘": "Ou appuyez sur ctrl / ⌘",
    "Content classification": "Classification du contenu",
    "Target language": "Langue cible",
    "Keep original": "Conserver l'original",
    "Add to collection": "Ajouter à la collection",
    "Auto": "Auto",
    "Summarize mode": "Mode résumé",
    "The website is behind a paywall - or not accessible by third parties": "Le site web est derrière un paywall - ou n'est pas accessible par des tiers",
    "Hint: It might be easier to use the <a href='https://www_sitestash_net/browser-extension' target='blank'>sitestash-browser-plugin</a> instead of inserting html code manually": "Conseil : il peut être plus facile d'utiliser le <a href='https://www.sitestash.net/browser-extension' target='blank'>plugin sitestash-browser</a> au lieu d'insérer le code html manuellement.",
    "Insert the complete html of the page here, so that we can analyze the content without accessing the website": "Insérer ici le code html complet de la page, afin que nous puissions analyser le contenu sans accéder au site web.",
    "${website_process_status}": "${website.process_status}",
    "Title could not be detected": "Le titre n'a pas pu être détecté",
    "Read more": "En savoir plus",
    "Name of the collection": "Nom de la collection",
    "Add collection": "Ajouter une collection",
    "Websites": "Sites web",
    "Sure?": "C'est sûr ?",
    "delete": "supprimer",
    "No websites stashed, yet": "Pas de sites web cachés pour l'instant",
    "Start adding websites easily by using the browser plugin": "Commencez à ajouter des sites web facilement en utilisant le plugin du navigateur",
    "or add a website manually here": "ou ajouter un site web manuellement ici",
    "Could not login": "Impossible de se connecter",
    "Could not login_ Wrong Password?": "Impossible de se connecter. Mauvais mot de passe ?",
    "E-Mail": "Courrier électronique",
    "Password": "Mot de passe",
    "Your login code": "Votre code d'accès",
    "If you have signed up correctly, you have now received a login code which you can place here": "Si vous vous êtes inscrit correctement, vous avez reçu un code de connexion que vous pouvez placer ici.",
    "We sent the login code": "Nous avons envoyé le code de connexion",
    "Click here, if you want to use your password instead": "Cliquez ici, si vous souhaitez utiliser votre mot de passe à la place.",
    "Remember login": "Mémoriser le login",
    "No Account, yet? Sign up here": "Pas encore de compte ? S'inscrire ici",
    "Theme": "Thème",
    "Collections": "Collections",
    "Your Profile": "Votre profil",
    "Search": "Recherche",
    "Signup": "S'inscrire",
    "Login": "Connexion",
    "5 websites": "5 sites web",
    "0 €": "0 €",
    "initial": "initial",
    "Select": "Sélectionner",
    "200 websites": "200 sites web",
    "5 €": "5 €",
    "per Month": "par mois",
    "Your profile": "Votre profil",
    "Language": "Langue",
    "Translate articles by default to": "Traduire les articles par défaut en",
    "Credits": "Crédits",
    "Number of credits left": "Nombre de crédits restants",
    "Articles": "Articles",
    "Buy more credits": "Acheter plus de crédits",
    "Log out": "Déconnexion",
    "E-mail has already been signed up": "L'e-mail a déjà été enregistré",
    "Passwords do not match": "Les mots de passe ne correspondent pas",
    "Could not signup": "Impossible de s'inscrire",
    "Confirm password": "Confirmer le mot de passe",
    "Invitation code": "Code d'invitation",
    "The summarizer will add extra costs": "Le résumé entraînera des coûts supplémentaires",
    "Translate": "Traduire",
    "Text is saving…": "Le texte est sauvegardé...",
    "Save text": "Enregistrer le texte",
    "Save custom text": "Enregistrer le texte personnalisé",
    "Create professional summarize": "Créer un résumé professionnel",
    "Professional Summary": "Résumé professionnel",
    "Analyze HTML": "Analyser le HTML",
    "Delete Article": "Supprimer l'article",
    "Change Article URL": "Modifier l'URL de l'article",
    "Remove custom summary": "Supprimer le résumé personnalisé",
    "Save and use custom summary above": "Enregistrer et utiliser le résumé personnalisé ci-dessus",
    "Change URL": "Modifier l'URL",
    "Summarize custom text instead of auto-extracted content": "Résumer un texte personnalisé au lieu d'un contenu extrait automatiquement",
    "Helpful if the summary seems to be wrong in regards of context": "Utile si le résumé semble erroné par rapport au contexte",
    "The update of the summary may take a minute or so - so please be patient": "La mise à jour du résumé peut prendre une minute ou deux - soyez patient.",
    "Summarize custom text": "Résumer un texte personnalisé",
    "Share Article": "Partager l'article",
    "Share edited article text": "Partager le texte de l'article édité",
    "Don't share article anymore": "Ne plus partager l'article",
    "Copied": "Copié",
    "Copy Share URL to Clipboard": "Copier l'URL du partage dans le presse-papiers",
    "Make this article public to share it with others": "Rendez cet article public pour le partager avec d'autres",
    "Share article": "Partager l'article",
    "Remove from favourites": "Supprimer des favoris",
    "Add to favourites": "Ajouter aux favoris",
    "Star": "Étoile",
    "There was a problem while processing the url": "Il y a eu un problème lors du traitement de l'url",
    "Maybe not all parts of the website are accessible or have relevant content? If the website is behind a paywall which you can access, you should try again with the browser plugin_": "Peut-être que toutes les parties du site ne sont pas accessibles ou ne présentent pas un contenu pertinent ? Si le site web se trouve derrière un paywall auquel vous pouvez accéder, vous devriez réessayer avec le plugin du navigateur.",
    "Alternatively you can read the article anyway, by clicking here": "Vous pouvez également lire l'article en cliquant ici.",
    "Note": "Note",
    "The original text was too long_ So parts from the end of the text are not included in the summary and may corrupt the correct gist_": "Le texte original était trop long. Les parties de la fin du texte ne sont donc pas incluses dans le résumé et peuvent altérer l'essentiel.",
    "Source: ": "Source : ",
    "Key moments": "Moments clés",
    "Hide key moments": "Cacher les moments clés",
    "Show image": "Afficher l'image",
    "Add image by url": "Ajouter une image par url",
    "Save": "Économiser",
    "Hide original content": "Cacher le contenu original",
    "Show original content": "Afficher le contenu original",
    "Original URL: ": "URL original : ",
    "Website is ready to read here in a moment": "Le site web est prêt à être lu ici dans un instant",
    "Website is already stashed": "Le site web est déjà caché",
    "Update Website": "Mise à jour du site web",
    "Website is behind paywall": "Le site web est derrière un paywall",
    "Content": "Contenu",
    "Translate to": "Traduire en",
    "Please login to use sitestash": "Veuillez vous connecter pour utiliser sitestash",
    "Sign up here for free": "S'inscrire ici gratuitement",
    "Log In": "Se connecter",
    "The provided credentials are incorrect": "Les informations d'identification fournies sont incorrectes",
    "Please confirm your e-mail-address first": "Veuillez d'abord confirmer votre adresse électronique",
    "Your login code:": "Votre code d'accès :",
    "If you did not request a login, no further action is required": "Si vous n'avez pas demandé d'ouverture de session, aucune autre action n'est requise.",
    "URL not found": "URL non trouvé",
    "Invalid URL": "URL invalide",
    "URL is probably behind cloudflare protecion": "L'URL est probablement derrière la protection cloudflare",
    "URL could not be accessed": "Impossible d'accéder à l'URL",
    "Not enough credits": "Pas assez de crédits",
    "Website already stashed": "Site web déjà caché",
    "Website is stashed": "Le site web est caché",
    "Not allowed": "Non autorisé",
    "No valid invitation code": "Pas de code d'invitation valide",
    "E-Mail already exists": "Le courriel existe déjà",
    "Password must be different than email": "Le mot de passe doit être différent de l'email",
    "Signup created - Please confirm your e-mail before logging in": "Inscription créée - Veuillez confirmer votre e-mail avant de vous connecter",
    "Language not supported": "La langue n'est pas prise en charge",
    "Resource not found": "Ressource non trouvée",
    "The password did not match": "Le mot de passe ne correspond pas",
    "Fast": "Rapide",
    "Accurate": "Précision",
    "Large content": "Un contenu important",
    "Large content and accurate summary": "Un contenu important et un résumé précis",
    "If you have signed up correctly, you have now received a login code": "Si vous vous êtes inscrit correctement, vous avez reçu un code d'accès.",
    "Too many login attempts": "Trop de tentatives de connexion",
    "Code or e-mail is invalid": "Le code ou l'e-mail n'est pas valide"
  }
}