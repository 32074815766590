import './WebsiteActions.scss'
import { Tooltip } from 'react-tooltip'
import { dateStringToLocaleDate, t } from '../../lib/helper'
import * as api from '../../lib/api'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'
import Clipboard from 'react-clipboard.js'
import OutsideClickHandler from 'react-outside-click-handler'

export function WebsiteActions({
  article,
  articleText,
  setArticle,
  setSoftLoading,
  saveArticleCustomText,
} = props) {
  const navigate = useNavigate()

  const [isSaving, setIsSaving] = useState(null)
  const [analyzeHTML, setAnalyzeHTML] = useState(null)
  const [url, setUrl] = useState(article.url || article.scrape_url)
  const [customSummary, setCustomSummary] = useState(article.custom_summary)
  const [copiedShareUrl, setCopiedShareUrl] = useState(null)
  const [showOption, setShowOption] = useState(null)
  const [isUpdatingSummary, setIsUpdatingSummary] = useState(false)

  async function handleArticleTextSave() {
    saveArticleCustomText()
  }

  async function handleReanalyzeArticle(ev) {
    ev.preventDefault()
    if (!confirm(t('Sure?') + ' ' + t('The summarizer will add extra costs'))) {
      return
    }
    setSoftLoading(true)
    await api.analyzeArticle(article.id, {
      analyze: analyzeHTML ? 'html' : 'url',
    })
    setSoftLoading(false)
  }

  async function translateWebsite(id, lang) {
    setSoftLoading(true)
    try {
      let website = (await api.translateWebsite(id, lang)).website
      if (website) {
        setArticle(website)
      }
    } catch (e) {
      console.error(e)
    }
    setSoftLoading(false)
  }

  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        setShowOption(null)
      }}
    >
      <ul>
        <Tooltip id="my-tooltip" />
        <li
          id="translate-action"
          className={showOption === 'translate-action' ? 'show' : ''}
          onMouseEnter={() => setShowOption('translate-action')}
        >
          <button className="icon">{t('Translate')}</button>
          <ul className="options">
            {['original', 'de', 'en-US', 'fr', 'es'].map((lan) => (
              <li key={lan} onClick={(ev) => translateWebsite(article.id, lan)}>
                {t(lan.replace(/\-.*$/, ''))}
              </li>
            ))}
          </ul>
        </li>
        <li id="save-action" onMouseEnter={() => setShowOption(null)}>
          <button
            className="icon"
            onClick={handleArticleTextSave}
            data-tooltip-id="my-tooltip"
            data-tooltip-content={
              isSaving ? t('Text is saving…') : t('Save text')
            }
            data-tooltip-place="left"
          >
            {t('Save custom text')}
          </button>
        </li>

        <li
          id="reanalyze-action"
          className={showOption === 'reanalyze-action' ? 'show' : ''}
          onMouseEnter={() => setShowOption('reanalyze-action')}
          style={{ display: 'none' }}
        >
          <button className="icon">{t('Create professional summarize')}</button>
          <div className="options">
            <form className="form" onSubmit={handleReanalyzeArticle}>
              <fieldset>
                <h3>{t('Professional Summary')}</h3>
                <p>
                  Create a professional and comprehensive summary, without
                  consuming the whole website / video.
                </p>
                <p>
                  You can summarize <b>public available</b> websites,
                  youtube-videos and pdf-files.
                </p>
                <p>
                  <b>
                    If the website is behind a paywall, check the "Analzye HTML"
                    option to send the already stashed content.
                  </b>
                </p>
                <div className="checkbox">
                  <label htmlFor="analyze-html">{t('Analyze HTML')}</label>
                  <input
                    type="checkbox"
                    id="analyze-html"
                    onChange={(ev) => setAnalyzeHTML(ev.target.checked)}
                  ></input>
                </div>
                <button type="submit">Create professional summary *</button>
                <p>
                  <small>* The costs will be ~ 0.4 - 1 USD</small>
                </p>
                <p>
                  <small>The process may take a while (about 1min)</small>
                </p>
              </fieldset>
            </form>
          </div>
        </li>
        <li id="trash-action" onMouseEnter={() => setShowOption(null)}>
          <button
            className="icon"
            data-tooltip-id="my-tooltip"
            data-tooltip-content={t('Delete Article')}
            data-tooltip-place="left"
            onClick={async () => {
              if (confirm(t('Sure?'))) {
                await api.deleteWebsite(article.id)
                navigate('/')
              }
            }}
          >
            {t('Delete Article')}
          </button>
        </li>
        <li
          id="edit-action"
          className={showOption === 'edit-action' ? 'show' : ''}
          onMouseEnter={() => setShowOption('edit-action')}
        >
          <button className="icon">{t('Change Article URL')}</button>
          <div className="options">
            <form
              className="form"
              onSubmit={async (ev) => {
                ev.preventDefault()
                setSoftLoading(true)
                let data = {
                  custom_summary: customSummary,
                }
                if (url !== article.scrape_url) {
                  data.url = url
                }
                let website = (await api.patchWebsite(article.id, data)).website
                setArticle(website)
                setSoftLoading(false)
              }}
            >
              <fieldset>
                <textarea
                  defaultValue={customSummary}
                  onChange={(ev) => setCustomSummary(ev.target.value)}
                ></textarea>
                <button className="small">
                  {!customSummary && article.custom_summary
                    ? t('Remove custom summary')
                    : t('Save and use custom summary above')}
                </button>
              </fieldset>
              <fieldset>
                <input
                  type="text"
                  defaultValue={url}
                  onChange={(ev) => setUrl(ev.target.value)}
                ></input>
                <button className="small">{t('Change URL')}</button>
              </fieldset>
              {articleText && (
                <fieldset>
                  <strong>
                    {t(
                      'Summarize custom text instead of auto-extracted content'
                    )}
                  </strong>
                  <br></br>
                  <p>
                    {t(
                      'Helpful if the summary seems to be wrong in regards of context'
                    )}
                    .{' '}
                    {t(
                      'The update of the summary may take a minute or so - so please be patient'
                    )}{' '}
                    :){' '}
                  </p>
                  <button
                    onClick={async () => {
                      setIsUpdatingSummary(true)
                      let data = await api.updateSummary(
                        article.id,
                        articleText
                      )
                      setIsUpdatingSummary(false)
                      setArticle(data.website)
                    }}
                    disabled={isUpdatingSummary}
                  >
                    {t('Summarize custom text')}
                  </button>
                </fieldset>
              )}
            </form>
          </div>
        </li>
        <li
          id="share-action"
          className={showOption === 'share-action' ? 'show' : ''}
          onMouseEnter={() => setShowOption('share-action')}
          // onMouseLeave={() => setShowOption(null)}
        >
          <button className="icon">{t('Share Article')}</button>
          <div className="options">
            <form className="form">
              <fieldset>
                {article.is_public ? (
                  <>
                    <p>
                      <a
                        href={`https://sitestash.net/s/${article.share_url}`}
                        target="_blank"
                      >
                        {`https://sitestash.net/s/${article.share_url}`}
                      </a>
                    </p>

                    {articleText && (
                      <p className="checkbox">
                        <label htmlFor="share-custom-text">
                          {t('Share edited article text')}
                        </label>
                        <input
                          id="share-custom-text"
                          type="checkbox"
                          defaultChecked={article.share_custom_text}
                          onChange={async (ev) => {
                            setSoftLoading(true)
                            let _article = await api.patchWebsite(article.id, {
                              share_custom_text: ev.target.checked,
                              custom_text: articleText,
                            })
                            setSoftLoading(false)
                            setArticle(_article.website)
                          }}
                        ></input>
                      </p>
                    )}
                    <button
                      className="small"
                      onClick={async (ev) => {
                        ev.preventDefault()
                        setSoftLoading(true)
                        let result = await api.patchWebsite(article.id, {
                          is_public: false,
                        })
                        setSoftLoading(false)
                        setArticle(result.website)
                      }}
                    >
                      {t("Don't share article anymore")}
                    </button>
                    <Clipboard
                      data-clipboard-text={`https://sitestash.net/s/${article.share_url}`}
                      onSuccess={() => {
                        setCopiedShareUrl(true)
                        setTimeout(() => setCopiedShareUrl(false), 1000)
                      }}
                      className="small"
                    >
                      {copiedShareUrl
                        ? t('Copied')
                        : t('Copy Share URL to Clipboard')}
                    </Clipboard>
                  </>
                ) : (
                  <>
                    <p>
                      {t('Make this article public to share it with others')}
                    </p>
                    <button
                      onClick={async (ev) => {
                        ev.preventDefault()
                        let result = await api.patchWebsite(article.id, {
                          is_public: true,
                        })
                        setArticle(result.website)
                      }}
                    >
                      {t('Share article')}
                    </button>
                  </>
                )}
              </fieldset>
            </form>
          </div>
        </li>
        <li
          id="star-action"
          // className={showOption === 'share-action' ? 'show' : ''}
          onMouseEnter={() => setShowOption('')}
          // onMouseLeave={() => setShowOption(null)}
        >
          <button
            className={['icon', article.is_starred ? 'starred' : ''].join(' ')}
            onClick={async (ev) => {
              ev.preventDefault()
              let result = await api.patchWebsite(article.id, {
                is_starred: !article.is_starred,
              })
              setArticle(result.website)
            }}
            data-tooltip-id="my-tooltip"
            data-tooltip-content={
              article.is_starred ? t('Remove from favourites') : t('Add to favourites')
            }
            data-tooltip-place="left"
          >
            {t('Star')}
          </button>
        </li>
      </ul>
    </OutsideClickHandler>
  )
}
