import './Home.scss'
import { currentUserState } from '../states/currentUserState'
import { searchQueryState } from '../states/searchQueryState'
import { t } from '../../lib/helper'
import * as api from '../../lib/api'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { ArticlePreview } from './ArticlePreview'
import { ArticleSearch } from './ArticleSearch'
import InfiniteScroll from 'react-infinite-scroller'
import { useAtom } from 'jotai'
import { toast } from 'react-toastify'

export function Home({ setActionMenu } = {}) {
  const [websites, setWebsites] = useState(null)
  const [limit, setLimit] = useState(20)
  const [hasMorePage, setHasMorePage] = useState(true)
  const [searchQuery, setSearchQuery] = useAtom(searchQueryState)
  const [compactView, setCompactView] = useState(
    localStorage.getItem('compactView') === 'true'
  )

  useEffect(() => {
    // TODO: cancel previous request
    if (searchQuery === ':focus:' || searchQuery === ':blur:') {
      return
    }
    api.websites({ limit, title: searchQuery, page: 1 }).then((r) => {
      setTimeout(() => {
        // toast('This is a test')
        setWebsites(r)
        if (r.length === limit) {
          setHasMorePage(true)
        }
      }, 100)
    })
  }, [searchQuery])

  function handleToggleCompactView() {
    let newValue = localStorage.getItem('compactView') !== 'true'
    localStorage.setItem('compactView', newValue)
    setCompactView(newValue)
    if (websites) {
      setWebsites([...websites])
    }
  }

  useEffect(() => {
    setActionMenu(
      <ul className="action-menu action-menu-home">
        <li className="grid" onClick={handleToggleCompactView}></li>
        <li
          className="favourites"
          onClick={() => {
            setSearchQuery(
              !searchQuery?.match(/is_starred/)
                ? ((searchQuery || '') + 'is_starred:').trim()
                : ''
            )
          }}
        ></li>
        <li
          className="menu-search"
          onClick={() => {
            location.hash = ''
            location.hash = '#article-search'
            setSearchQuery(':focus:')
            setTimeout(() => {
              setSearchQuery('')
            }, 100)
          }}
        ></li>
      </ul>
    )
  }, [websites])

  function loadFunc(page) {
    setHasMorePage(false)

    api.websites({ limit, title: searchQuery, page }).then((r) => {
      setWebsites([...(websites || []), ...r])
      if (r.length === limit) {
        setHasMorePage(true)
      }
    })
  }

  return (
    <div
      className={['home-stash', compactView ? 'compact-view' : '']
        .filter((v) => !!v)
        .join(' ')}
    >
      <ArticleSearch></ArticleSearch>
      {websites?.length === 0 && !searchQuery && (
        <div className="no-stash" style={{ textAlign: 'center', opacity: 0.5 }}>
          <h2>{t('No websites stashed, yet')}</h2>
          <p>
            <a
              href="https://www.sitestash.net/browser-extension"
              target="_blank"
            >
              {t('Start adding websites easily by using the browser plugin')}
            </a>
            <br></br>
            <Link to="/add">{t('or add a website manually here')}</Link>
          </p>
        </div>
      )}
      {websites?.length > 0 && (
        <InfiniteScroll
          pageStart={1}
          loadMore={loadFunc}
          hasMore={hasMorePage}
          threshold={2000}
          loader={
            <div className="loader" key={0}>
              Loading…
            </div>
          }
        >
          <ul className="stash">
            {websites.map((website, i) => (
              <li key={'website-preview-li-' + website.id}>
                <ArticlePreview
                  website={website}
                  i={i}
                  compactView={compactView}
                ></ArticlePreview>
              </li>
            ))}
          </ul>
        </InfiniteScroll>
      )}
    </div>
  )
}
