import { createRoot } from 'react-dom/client'
import { App } from './app/App'
import { RecoilRoot } from 'recoil'
import './i18n/i18n'
import * as Sentry from "@sentry/react";
import { BrowserRouter } from 'react-router-dom';

if (!window?.location?.host?.startsWith('localhost')) {
    Sentry.init({
        dsn: "https://a211f296b134585aa44edc10ea547aa3@o1128238.ingest.sentry.io/4505935375237120",
        integrations: [
          new Sentry.BrowserTracing({
            // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: [
                "localhost",
                /^https:\/\/app\.sitestash\.net\//
            ],
          }),
          new Sentry.Replay(),
        ],
        // Performance Monitoring
        tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
      });
}

const container = document.getElementById('app-container')
const root = createRoot(container)

root.render(
  <RecoilRoot>
      <BrowserRouter>
        <App />
      </BrowserRouter>
  </RecoilRoot>
)
