{
  "de": {
    "sitestash": "sitestash",
    "Please enter a URL": "Bitte geben Sie eine URL ein",
    "Some problem occured while adding site": "Beim Hinzufügen der Website ist ein Problem aufgetreten",
    "Please ensure that you have access to the site": "Bitte stellen Sie sicher, dass Sie Zugang zur Website haben",
    "In most cases that means that you need to be logged in on the website you want to stash": "In den meisten Fällen bedeutet dies, dass Sie auf der Website, auf der Sie Ihre Daten speichern möchten, angemeldet sein müssen.",
    "Then try again to the save the website with the browser plugin": "Versuchen Sie dann erneut, die Website mit dem Browser-Plugin zu speichern",
    "Add Website": "Website hinzufügen",
    "Click here to show all options": "Klicken Sie hier, um alle Optionen anzuzeigen",
    "Or press ctrl / ⌘": "Oder drücken Sie ctrl / ⌘.",
    "Content classification": "Klassifizierung des Inhalts",
    "Target language": "Zielsprache",
    "Keep original": "Original beibehalten",
    "Add to collection": "Zur Sammlung hinzufügen",
    "Auto": "Auto",
    "Summarize mode": "Modus \"Zusammenfassen",
    "The website is behind a paywall - or not accessible by third parties": "Die Website befindet sich hinter einer Paywall - oder ist für Dritte nicht zugänglich",
    "Hint: It might be easier to use the <a href='https://www_sitestash_net/browser-extension' target='blank'>sitestash-browser-plugin</a> instead of inserting html code manually": "Hinweis: Es könnte einfacher sein, das <a href='https://www.sitestash.net/browser-extension' target='blank'>sitestash-browser-plugin</a> zu verwenden, anstatt den HTML-Code manuell einzufügen",
    "Insert the complete html of the page here, so that we can analyze the content without accessing the website": "Fügen Sie hier die vollständige HTML der Seite ein, damit wir den Inhalt analysieren können, ohne auf die Website zuzugreifen",
    "${website_process_status}": "${website.process_status}",
    "Title could not be detected": "Titel konnte nicht gefunden werden",
    "Read more": "Mehr lesen",
    "Name of the collection": "Name der Sammlung",
    "Add collection": "Sammlung hinzufügen",
    "Websites": "Webseiten",
    "Sure?": "Sicher?",
    "delete": "löschen",
    "No websites stashed, yet": "Noch keine Websites versteckt",
    "Start adding websites easily by using the browser plugin": "Einfaches Hinzufügen von Websites mit dem Browser-Plugin",
    "or add a website manually here": "oder fügen Sie hier manuell eine Website hinzu",
    "Could not login": "Keine Anmeldung möglich",
    "Could not login_ Wrong Password?": "Sie konnten sich nicht anmelden. Falsches Passwort?",
    "E-Mail": "E-Mail",
    "Password": "Passwort",
    "Your login code": "Ihr Anmeldecode",
    "If you have signed up correctly, you have now received a login code which you can place here": "Wenn Sie sich korrekt angemeldet haben, haben Sie jetzt einen Anmeldecode erhalten, den Sie hier eingeben können",
    "We sent the login code": "Wir haben den Anmeldecode gesendet",
    "Click here, if you want to use your password instead": "Klicken Sie hier, wenn Sie stattdessen Ihr Passwort verwenden möchten",
    "Remember login": "Anmeldung merken",
    "No Account, yet? Sign up here": "Noch kein Konto? Hier anmelden",
    "Theme": "Thema",
    "Collections": "Sammlungen",
    "Your Profile": "Ihr Profil",
    "Search": "Suche",
    "Signup": "Anmeldung",
    "Login": "Anmeldung",
    "5 websites": "5 Webseiten",
    "0 €": "0 €",
    "initial": "erste",
    "Select": "Wählen Sie",
    "200 websites": "200 Websites",
    "5 €": "5 €",
    "per Month": "pro Monat",
    "Your profile": "Ihr Profil",
    "Language": "Sprache",
    "Translate articles by default to": "Artikel standardmäßig übersetzen in",
    "Credits": "Guthaben",
    "Number of credits left": "Anzahl der verbleibenden Credits",
    "Articles": "Artikel",
    "Buy more credits": "Mehr Guthaben kaufen",
    "Log out": "Abmelden",
    "E-mail has already been signed up": "Die E-Mail wurde bereits registriert",
    "Passwords do not match": "Passwörter stimmen nicht überein",
    "Could not signup": "Konnte sich nicht anmelden",
    "Confirm password": "Bestätigen Sie das Passwort",
    "Invitation code": "Einladungscode",
    "The summarizer will add extra costs": "Die Zusammenfassung wird zusätzliche Kosten verursachen",
    "Translate": "Übersetzen Sie",
    "Text is saving…": "Text wird gespeichert...",
    "Save text": "Text speichern",
    "Save custom text": "Benutzerdefinierten Text speichern",
    "Create professional summarize": "Professionelle Zusammenfassungen erstellen",
    "Professional Summary": "Professionelle Zusammenfassung",
    "Analyze HTML": "HTML analysieren",
    "Delete Article": "Artikel löschen",
    "Change Article URL": "Artikel-URL ändern",
    "Remove custom summary": "Benutzerdefinierte Zusammenfassung entfernen",
    "Save and use custom summary above": "Speichern und benutzerdefinierte Zusammenfassung oben verwenden",
    "Change URL": "URL ändern",
    "Summarize custom text instead of auto-extracted content": "Zusammenfassen von benutzerdefiniertem Text anstelle von automatisch extrahiertem Inhalt",
    "Helpful if the summary seems to be wrong in regards of context": "Hilfreich, wenn die Zusammenfassung in Bezug auf den Kontext falsch zu sein scheint",
    "The update of the summary may take a minute or so - so please be patient": "Die Aktualisierung der Zusammenfassung kann etwa eine Minute dauern - haben Sie also bitte etwas Geduld",
    "Summarize custom text": "Benutzerdefinierten Text zusammenfassen",
    "Share Article": "Artikel teilen",
    "Share edited article text": "Bearbeiteten Artikeltext teilen",
    "Don't share article anymore": "Artikel nicht mehr teilen",
    "Copied": "Kopiert",
    "Copy Share URL to Clipboard": "Freigabe-URL in die Zwischenablage kopieren",
    "Make this article public to share it with others": "Machen Sie diesen Artikel öffentlich, um ihn mit anderen zu teilen",
    "Share article": "Artikel teilen",
    "Remove from favourites": "Aus den Favoriten entfernen",
    "Add to favourites": "Zu Favoriten hinzufügen",
    "Star": "Stern",
    "There was a problem while processing the url": "Es gab ein Problem bei der Verarbeitung der Url",
    "Maybe not all parts of the website are accessible or have relevant content? If the website is behind a paywall which you can access, you should try again with the browser plugin_": "Vielleicht sind nicht alle Teile der Website zugänglich oder haben relevante Inhalte? Wenn die Website hinter einer Paywall liegt, auf die Sie zugreifen können, sollten Sie es erneut mit dem Browser-Plugin versuchen.",
    "Alternatively you can read the article anyway, by clicking here": "Alternativ können Sie den Artikel auch lesen, indem Sie hier klicken",
    "Note": "Hinweis",
    "The original text was too long_ So parts from the end of the text are not included in the summary and may corrupt the correct gist_": "Der ursprüngliche Text war zu lang. Daher sind Teile am Ende des Textes nicht in der Zusammenfassung enthalten, was den Sinn des Textes verfälschen könnte.",
    "Source: ": "Quelle: ",
    "Key moments": "Wichtige Momente",
    "Hide key moments": "Schlüsselmomente ausblenden",
    "Show image": "Bild anzeigen",
    "Add image by url": "Bild per Url hinzufügen",
    "Save": "Speichern Sie",
    "Hide original content": "Originalinhalte ausblenden",
    "Show original content": "Originalinhalte anzeigen",
    "Original URL: ": "Ursprüngliche URL: ",
    "Website is ready to read here in a moment": "Die Website ist in Kürze hier zu lesen",
    "Website is already stashed": "Website ist bereits versteckt",
    "Update Website": "Website aktualisieren",
    "Website is behind paywall": "Website ist hinter einer Paywall",
    "Content": "Inhalt",
    "Translate to": "Übersetzen in",
    "Please login to use sitestash": "Bitte loggen Sie sich ein, um sitestash zu benutzen",
    "Sign up here for free": "Registrieren Sie sich hier kostenlos",
    "Log In": "Einloggen",
    "The provided credentials are incorrect": "Die angegebenen Anmeldeinformationen sind falsch",
    "Please confirm your e-mail-address first": "Bitte bestätigen Sie zunächst Ihre E-Mail Adresse",
    "Your login code:": "Ihr Anmeldecode:",
    "If you did not request a login, no further action is required": "Wenn Sie keine Anmeldung beantragt haben, sind keine weiteren Schritte erforderlich.",
    "URL not found": "URL nicht gefunden",
    "Invalid URL": "Ungültige URL",
    "URL is probably behind cloudflare protecion": "URL ist wahrscheinlich hinter cloudflare Schutz",
    "URL could not be accessed": "URL konnte nicht aufgerufen werden",
    "Not enough credits": "Nicht genug Kredite",
    "Website already stashed": "Website bereits versteckt",
    "Website is stashed": "Website ist versteckt",
    "Not allowed": "Nicht erlaubt",
    "No valid invitation code": "Kein gültiger Einladungscode",
    "E-Mail already exists": "E-Mail existiert bereits",
    "Password must be different than email": "Das Passwort muss sich von der E-Mail unterscheiden",
    "Signup created - Please confirm your e-mail before logging in": "Anmeldung erstellt - Bitte bestätigen Sie Ihre E-Mail, bevor Sie sich anmelden",
    "Language not supported": "Sprache nicht unterstützt",
    "Resource not found": "Ressource nicht gefunden",
    "The password did not match": "Das Passwort stimmt nicht überein",
    "Fast": "Schnell",
    "Accurate": "Genaue",
    "Large content": "Großer Inhalt",
    "Large content and accurate summary": "Umfangreicher Inhalt und genaue Zusammenfassung",
    "If you have signed up correctly, you have now received a login code": "Wenn Sie sich korrekt angemeldet haben, haben Sie jetzt einen Anmeldecode erhalten",
    "Too many login attempts": "Zu viele Anmeldeversuche",
    "Code or e-mail is invalid": "Code oder E-Mail ist ungültig"
  }
}