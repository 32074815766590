export function LoremIpsum() {
    return (
        <div className="limit-width">
            <button className="button">Primary Button</button>
            <div className='colors'>
                <div className="color-red">Red</div>
                <div className="color-green">Green</div>
                <div className="color-violet">Violet</div>
                <div className="color-yellow">Yellow</div>
                <div className="color-blue">Blue</div>
                <br></br>
                <div className="gradient-red button">Red</div>
                <div className="gradient-green button">Green</div>
                <div className="gradient-violet button">Violet</div>
                <div className="gradient-yellow button">Yellow</div>
                <div className="gradient-blue button">Blue</div>
                <div className="no-color button">No Color</div>
                <br></br>
                <div>
                    <div className="gradient-red text-masked">Red</div>
                </div>
                <div>
                    <div className="gradient-green text-masked">Green</div>
                </div>
                <div>
                    <div className="gradient-violet text-masked">Violet</div>
                </div>
                <div>
                    <div className="gradient-yellow text-masked">Yellow</div>
                </div>
                <div>
                    <div className="gradient-blue text-masked">Blue</div>
                </div>
            </div>
        </div>
    )
}
