[
  "adweek.com",
  "ad.nl",
  "ambito.com",
  "americanbanker.com",
  "baltimoresun.com",
  "barrons.com",
  "bloomberg.com",
  "bloombergquint.com",
  "bndestem.nl",
  "bostonglobe.com",
  "bd.nl",
  "brisbanetimes.com.au",
  "businessinsider.com",
  "caixinglobal.com",
  "centralwesterndaily.com.au",
  "cen.acs.org",
  "chicagotribune.com",
  "corriere.it",
  "chicagobusiness.com",
  "dailypress.com",
  "gelderlander.nl",
  "groene.nl",
  "demorgen.be",
  "denverpost.com",
  "speld.nl",
  "destentor.nl",
  "tijd.be",
  "volkskrant.nl",
  "df.cl",
  "editorialedomani.it",
  "dynamed.com",
  "ed.nl",
  "elmercurio.com",
  "elmundo.es",
  "elpais.com",
  "elperiodico.com",
  "elu24.ee",
  "britannica.com",
  "estadao.com.br",
  "examiner.com.au",
  "expansion.com",
  "fnlondon.com",
  "financialpost.com",
  "ft.com",
  "firstthings.com",
  "foreignpolicy.com",
  "fortune.com",
  "genomeweb.com",
  "glassdoor.com",
  "globes.co.il",
  "grubstreet.com",
  "haaretz.com",
  "haaretz.co.il",
  "harpers.org",
  "courant.com",
  "hbr.org",
  "hbrchina.org",
  "heraldsun.com.au",
  "fd.nl",
  "historyextra.com",
  "humo.be",
  "ilmanifesto.it",
  "inc.com",
  "interest.co.nz",
  "investorschronicle.co.uk",
  "lanacion.com.ar",
  "repubblica.it",
  "lastampa.it",
  "latercera.com",
  "lavoixdunord.fr",
  "lecho.be",
  "ledevoir.com",
  "leparisien.fr",
  "lesechos.fr",
  "loebclassics.com",
  "lrb.co.uk",
  "labusinessjournal.com",
  "latimes.com",
  "medium.com",
  "medscape.com",
  "mexiconewsdaily.com",
  "sloanreview.mit.edu",
  "technologyreview.com",
  "mv-voice.com",
  "nationalgeographic.com",
  "nationalpost.com",
  "nzz.ch",
  "newstatesman.com",
  "nydailynews.com",
  "nymag.com",
  "nzherald.co.nz",
  "nrc.nl",
  "ntnews.com.au",
  "ocregister.com",
  "orlandosentinel.com",
  "paloaltoonline.com",
  "parool.nl",
  "postimees.ee",
  "pzc.nl",
  "qz.com",
  "quora.com",
  "gelocal.it",
  "republic.ru",
  "reuters.com",
  "sandiegouniontribune.com",
  "sfchronicle.com",
  "scientificamerican.com",
  "seekingalpha.com",
  "slate.com",
  "sofrep.com",
  "startribune.com",
  "statista.com",
  "stuff.co.nz",
  "\"sueddeutsche.de\"",
  "sun-sentinel.com",
  "techinasia.com",
  "telegraaf.nl",
  "time.com",
  "adelaidenow.com.au",
  "theadvocate.com.au",
  "theage.com.au",
  "the-american-interest.com",
  "theathletic.com",
  "theathletic.co.uk",
  "theatlantic.com",
  "afr.com",
  "theaustralian.com.au",
  "bizjournals.com",
  "canberratimes.com.au",
  "thecourier.com.au",
  "couriermail.com.au",
  "thecut.com",
  "dailytelegraph.com.au",
  "thediplomat.com",
  "economist.com",
  "theglobeandmail.com",
  "theherald.com.au",
  "thehindu.com",
  "irishtimes.com",
  "japantimes.co.jp",
  "kansascity.com",
  "themarker.com",
  "mercurynews.com",
  "themercury.com.au",
  "mcall.com",
  "thenation.com",
  "thenational.scot",
  "news-gazette.com",
  "newyorker.com",
  "nytimes.com",
  "theolivepress.es",
  "inquirer.com",
  "thesaturdaypaper.com.au",
  "seattletimes.com",
  "spectator.com.au",
  "spectator.co.uk",
  "spectator.us",
  "smh.com.au",
  "telegraph.co.uk",
  "thestar.com",
  "wsj.com",
  "washingtonpost.com",
  "thewrap.com",
  "the-tls.co.uk",
  "towardsdatascience.com",
  "trouw.nl",
  "tubantia.nl",
  "vanityfair.com",
  "vn.nl",
  "vulture.com",
  "journalnow.com",
  "wired.com",
  "zeit.de"
]
