import { useEffect, useState } from 'react'
import './Profile.scss'
import { currentUserState } from '../states/currentUserState'
import { Link } from 'react-router-dom'
import { t } from '../../lib/helper'
import * as api from '../../lib/api'
import { useAtom } from 'jotai'

export function Profile({ setActionMenu } = {}) {
  const [currentUser] = useAtom(currentUserState)
  const [credits, setCredits] = useState(null)
  const [user, setUser] = useState(null)
  const [languages, setLanguages] = useState(null)
  const [uiLanguages, setUiLanguages] = useState(null)

  setActionMenu(null)

  useEffect(() => {
    if (!currentUser) {
      return
    }
    api.getUserCredits().then((data) => {
      setCredits(data)
    })
    api.user().then((data) => {
      setUser(data)
    })
    api.languages().then((data) => {
      setLanguages(data.languages)
      setUiLanguages(data.ui_languages)
    })
  }, [currentUser])

  return (
    <div className="profile limit-width form">
      <h3>{t('Your profile')}</h3>
      {currentUser && (
        <p>
          {t('E-Mail')}: {currentUser.email}
        </p>
      )}
      <br></br>
      <h3>{t('Language')}</h3>
      {user && languages && uiLanguages && (
        <>
          <p>
            <select
              onChange={async (ev) => {
                await api
                  .patchUser({ language: ev.target.value })
                  .then(() => location.reload())
              }}
              defaultValue={user.language}
            >
              {/* <option value={''}>{t('Select a language')}</option> */}
              {Object.entries(uiLanguages).map((language, i) => (
                <option
                  key={'language-option-' + language[0]}
                  value={language[0]}
                >
                  {language[1]}
                </option>
              ))}
            </select>
          </p>
          <br></br>
          <h3>{t('Translate articles by default to')}:</h3>
          <p>
            <select
              onChange={(ev) => {
                api
                  .patchUser({ translate_to_language: ev.target.value })
                  .then(() => location.reload())
              }}
              defaultValue={user.translate_to_language}
            >
              {[
                ...[['', t('Keep original')]],
                ...Object.entries(languages),
              ].map((language, i) => (
                <option
                  key={'language-option-' + language[0]}
                  value={language[0]}
                >
                  {language[1]}
                </option>
              ))}
            </select>
          </p>
        </>
      )}
      <br></br>
      {credits && (
        <div>
          <h3>{t('Credits')}</h3>
          <p>
            {t('Number of credits left')}: {credits.redeemable_credits} (~{' '}
            {Math.floor(credits.redeemable_credits / 10)} {t('Articles')})
          </p>
          <br></br>
        </div>
      )}
      <p>
        <Link to="/plans" className="button">
          {t('Buy more credits')}
        </Link>
      </p>
      <br></br>
      <p>
        <Link to="/logout" className="button">
          {t('Log out')}
        </Link>
      </p>
    </div>
  )
}
