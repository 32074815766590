{
    "en": {
        "sitestash": "sitestash",
        "Please enter a URL": "Please enter a URL",
        "Some problem occured while adding site": "Some problem occured while adding site",
        "Please ensure that you have access to the site": "Please ensure that you have access to the site",
        "In most cases that means that you need to be logged in on the website you want to stash": "In most cases that means that you need to be logged in on the website you want to stash",
        "Then try again to the save the website with the browser plugin": "Then try again to the save the website with the browser plugin",
        "Add Website": "Add Website",
        "Click here to show all options": "Click here to show all options",
        "Or press ctrl \/ \u2318": "Or press ctrl \/ \u2318",
        "Content classification": "Content classification",
        "Target language": "Target language",
        "Keep original": "Keep original",
        "Add to collection": "Add to collection",
        "Auto": "Auto",
        "Summarize mode": "Summarize mode",
        "The website is behind a paywall - or not accessible by third parties": "The website is behind a paywall - or not accessible by third parties",
        "Hint: It might be easier to use the <a href='https:\/\/www_sitestash_net\/browser-extension' target='blank'>sitestash-browser-plugin<\/a> instead of inserting html code manually": "Hint: It might be easier to use the <a href='https:\/\/www.sitestash.net\/browser-extension' target='blank'>sitestash-browser-plugin<\/a> instead of inserting html code manually",
        "Insert the complete html of the page here, so that we can analyze the content without accessing the website": "Insert the complete html of the page here, so that we can analyze the content without accessing the website",
        "${website_process_status}": "${website.process_status}",
        "Title could not be detected": "Title could not be detected",
        "Read more": "Read more",
        "Name of the collection": "Name of the collection",
        "Add collection": "Add collection",
        "Websites": "Websites",
        "Sure?": "Sure?",
        "delete": "delete",
        "No websites stashed, yet": "No websites stashed, yet",
        "Start adding websites easily by using the browser plugin": "Start adding websites easily by using the browser plugin",
        "or add a website manually here": "or add a website manually here",
        "Could not login": "Could not login",
        "Could not login_ Wrong Password?": "Could not login. Wrong Password?",
        "E-Mail": "E-Mail",
        "Password": "Password",
        "Your login code": "Your login code",
        "If you have signed up correctly, you have now received a login code which you can place here": "If you have signed up correctly, you have now received a login code which you can place here",
        "We sent the login code": "We sent the login code",
        "Click here, if you want to use your password instead": "Click here, if you want to use your password instead",
        "Remember login": "Remember login",
        "No Account, yet? Sign up here": "No Account, yet? Sign up here",
        "Theme": "Theme",
        "Collections": "Collections",
        "Your Profile": "Your Profile",
        "Search": "Search",
        "Signup": "Signup",
        "Login": "Login",
        "5 websites": "5 websites",
        "0 \u20ac": "0 \u20ac",
        "initial": "initial",
        "Select": "Select",
        "200 websites": "200 websites",
        "5 \u20ac": "5 \u20ac",
        "per Month": "per Month",
        "Your profile": "Your profile",
        "Language": "Language",
        "Translate articles by default to": "Translate articles by default to",
        "Credits": "Credits",
        "Number of credits left": "Number of credits left",
        "Articles": "Articles",
        "Buy more credits": "Buy more credits",
        "Log out": "Log out",
        "E-mail has already been signed up": "E-mail has already been signed up",
        "Passwords do not match": "Passwords do not match",
        "Could not signup": "Could not signup",
        "Confirm password": "Confirm password",
        "Invitation code": "Invitation code",
        "The summarizer will add extra costs": "The summarizer will add extra costs",
        "Translate": "Translate",
        "Text is saving\u2026": "Text is saving\u2026",
        "Save text": "Save text",
        "Save custom text": "Save custom text",
        "Create professional summarize": "Create professional summarize",
        "Professional Summary": "Professional Summary",
        "Analyze HTML": "Analyze HTML",
        "Delete Article": "Delete Article",
        "Change Article URL": "Change Article URL",
        "Remove custom summary": "Remove custom summary",
        "Save and use custom summary above": "Save and use custom summary above",
        "Change URL": "Change URL",
        "Summarize custom text instead of auto-extracted content": "Summarize custom text instead of auto-extracted content",
        "Helpful if the summary seems to be wrong in regards of context": "Helpful if the summary seems to be wrong in regards of context",
        "The update of the summary may take a minute or so - so please be patient": "The update of the summary may take a minute or so - so please be patient",
        "Summarize custom text": "Summarize custom text",
        "Share Article": "Share Article",
        "Share edited article text": "Share edited article text",
        "Don't share article anymore": "Don't share article anymore",
        "Copied": "Copied",
        "Copy Share URL to Clipboard": "Copy Share URL to Clipboard",
        "Make this article public to share it with others": "Make this article public to share it with others",
        "Share article": "Share article",
        "Remove from favourites": "Remove from favourites",
        "Add to favourites": "Add to favourites",
        "Star": "Star",
        "There was a problem while processing the url": "There was a problem while processing the url",
        "Maybe not all parts of the website are accessible or have relevant content? If the website is behind a paywall which you can access, you should try again with the browser plugin_": "Maybe not all parts of the website are accessible or have relevant content? If the website is behind a paywall which you can access, you should try again with the browser plugin.",
        "Alternatively you can read the article anyway, by clicking here": "Alternatively you can read the article anyway, by clicking here",
        "Note": "Note",
        "The original text was too long_ So parts from the end of the text are not included in the summary and may corrupt the correct gist_": "The original text was too long. So parts from the end of the text are not included in the summary and may corrupt the correct gist.",
        "Source: ": "Source: ",
        "Key moments": "Key moments",
        "Hide key moments": "Hide key moments",
        "Show image": "Show image",
        "Add image by url": "Add image by url",
        "Save": "Save",
        "Hide original content": "Hide original content",
        "Show original content": "Show original content",
        "Original URL: ": "Original URL: ",
        "Website is ready to read here in a moment": "Website is ready to read here in a moment",
        "Website is already stashed": "Website is already stashed",
        "Update Website": "Update Website",
        "Website is behind paywall": "Website is behind paywall",
        "Content": "Content",
        "Translate to": "Translate to",
        "Please login to use sitestash": "Please login to use sitestash",
        "Sign up here for free": "Sign up here for free",
        "Log In": "Log In",
        "The provided credentials are incorrect": "The provided credentials are incorrect",
        "Please confirm your e-mail-address first": "Please confirm your e-mail-address first",
        "Your login code:": "Your login code:",
        "If you did not request a login, no further action is required": "If you did not request a login, no further action is required",
        "URL not found": "URL not found",
        "Invalid URL": "Invalid URL",
        "URL is probably behind cloudflare protecion": "URL is probably behind cloudflare protecion",
        "URL could not be accessed": "URL could not be accessed",
        "Not enough credits": "Not enough credits",
        "Website already stashed": "Website already stashed",
        "Website is stashed": "Website is stashed",
        "Not allowed": "Not allowed",
        "No valid invitation code": "No valid invitation code",
        "E-Mail already exists": "E-Mail already exists",
        "Password must be different than email": "Password must be different than email",
        "Signup created - Please confirm your e-mail before logging in": "Signup created - Please confirm your e-mail before logging in",
        "Language not supported": "Language not supported",
        "Resource not found": "Resource not found",
        "The password did not match": "The password did not match",
        "Fast": "Fast",
        "Accurate": "Accurate",
        "Large content": "Large content",
        "Large content and accurate summary": "Large content and accurate summary",
        "If you have signed up correctly, you have now received a login code": "If you have signed up correctly, you have now received a login code",
        "Too many login attempts": "Too many login attempts",
        "Code or e-mail is invalid": "Code or e-mail is invalid"
    }
}