import axios from 'axios'

axios.defaults.baseURL = window?.location?.host?.startsWith('localhost') /* ||
  !('update_url' in chrome?.runtime?.getManifest())*/
  ? 'http://localhost:8000'
  : 'https://api.sitestash.net'

// axios.defaults.baseURL = 'http://localhost:8000'

let auth_token = localStorage.getItem('authToken') || null

export function setBearerAuthToken(authToken, storage = null) {
  auth_token = authToken
  axios.defaults.headers.common['Authorization'] = `Bearer ${authToken}`
  if (storage) {
    storage.setItem('authToken', authToken)
  }
}

export function getAuthToken() {
  return auth_token
}

export async function logout() {
  localStorage.removeItem('authToken')
  sessionStorage.removeItem('authToken')
  return await axios.post('/api/v1/logout')
}

export async function user() {
  return (await axios.get('/api/v1/user')).data
}

export async function patchUser({
  email,
  language,
  translate_to_language,
} = {}) {
  return (
    await axios.patch('/api/v1/user', {
      email,
      language,
      translate_to_language,
    })
  ).data
}

export async function languages() {
  return (await axios.get('/api/v1/languages')).data
}

export async function summarizer() {
  return (await axios.get('/api/v1/summarizer')).data
}

function userAgentDevice() {
  return navigator.userAgent.replace(/\s.+$/, '')
}

export async function receiveAuthToken(email, password, deviceName = null) {
  if (!deviceName) {
    deviceName = userAgentDevice()
  }
  await axios.get('/sanctum/csrf-cookie')
  // this request above did just set cookie in application cookie, otherwise we'll get a CSRF mismatch error
  let { data } = await axios.post('/sanctum/token', {
    email,
    password,
    device_name: deviceName,
  })
  return data
}

export async function passwordlessLogin(email) {
  // await axios.post('/passwordless-login/token')
  let { data } = await axios.post('/passwordless-login', {
    email,
    device_name: userAgentDevice(),
  })
  return data
}

export async function passwordlessLoginReceiveToken(email, code) {
  let { data } = await axios.post('/passwordless-login/token', {
    email,
    code,
    device_name: userAgentDevice(),
  })
  return data.token
}

export async function csrfCookie() {
  return await axios.get('/sanctum/csrf-cookie')
}

export async function signup({ email, password, invitationCode } = {}) {
  return (
    await axios.post('/api/v1/signup', {
      email,
      password,
      invitation_code: invitationCode,
    })
  )?.data
}

export async function emailIsAvailableForSignup(email) {
  let res = await axios.get(`/api/v1/signup/email_is_available/${email}`)
  return res.data.email_is_available && res.data.email_is_valid
}

export async function sendPasswordReset(email) {
  return await axios.post('/api/v1/forgot-password', {
    email,
  })
}

export async function addWebsite({
  html,
  url,
  is_behind_paywall,
  language,
  summarize_mode,
  content_classification,
  collection_id,
  custom_article_text,
} = {}) {
  return (
    await axios.post('/api/v1/website', {
      html,
      url,
      language,
      summarize_mode,
      is_behind_paywall,
      content_classification,
      collection_id,
      custom_article_text,
    })
  ).data
}

export async function websiteExists(url) {
  return (
    await axios.get(`/api/v1/website_exists?url=${encodeURIComponent(url)}`)
  ).data
}

export async function getWebsite(id) {
  return (await axios.get(`/api/v1/websites/${id}`)).data?.website
}

export async function getUserCredits() {
  return (await axios.get(`/api/v1/user/credits`)).data
}

export async function translateWebsite(id, targetLanguage) {
  return (
    await axios.post(`/api/v1/websites/${id}/translate/${targetLanguage}`)
  ).data
}

export async function deleteWebsite(id, imageUrl) {
  return await axios.delete(`/api/v1/websites/${id}`)
}

export async function websites({
  limit = 20,
  url = '',
  page = 1,
  title = '',
} = {}) {
  if (!title) {
    title = ''
  }
  let query = new URLSearchParams({
    limit,
    url,
    title,
    page,
  })
  return (
    (await axios.get(`/api/v1/websites${query ? '?' + query.toString() : ''}`))
      .data?.websites || []
  )
}

export async function setDisplayImageOnWebsite(
  websiteId,
  imageUrl,
  save = false
) {
  return (
    await axios.post(`/api/v1/websites/${websiteId}/display_image`, {
      image_url: imageUrl,
      save,
    })
  ).data
}

export async function patchWebsite(websiteId, params) {
  return (await axios.patch(`/api/v1/websites/${websiteId}`, params)).data
}

export async function analyzeArticle(websiteId, { url, html, analyze } = {}) {
  return await axios.post(`/api/v1/websites/${websiteId}/analyze`, {
    html,
    url,
    analyze,
  })
}

export async function setProcessStatusToFinished(websiteId) {
  return (
    await axios.post(`/api/v1/websites/${websiteId}/process_status_finished`)
  ).data
}

export async function createCollection(name) {
  return (await axios.post(`/api/v1/collection`, { name })).data
}

export async function collections() {
  return (await axios.get(`/api/v1/collections`)).data.collections
}

export async function deleteCollection(id) {
  return (await axios.delete(`/api/v1/collections/${id}`)).data
}

export async function patchCollection(id, { name } = {}) {
  return (
    await axios.patch(`/api/v1/collections/${id}`, {
      name,
    })
  ).data
}

export async function possibleContentCategories(url = null) {
  return (
    await axios.get(
      `/api/v1/possible_content_categories${url ? '?url=' + encodeURIComponent(url) : ''}`
    )
  ).data
}

export async function updateSummary(id, text, targetLanguage = null) {
  return (
    await axios.post(`/api/v1/websites/${id}/summary`, {
      text,
      target_language: targetLanguage,
    })
  ).data
}

export async function addWebsiteToCollection(websiteId, collectionId) {
  return (
    await axios.post(
      `/api/v1/collections/${collectionId}/websites/${websiteId}`
    )
  ).data
}

export async function removeWebsiteFromCollection(websiteId, collectionId) {
  return (
    await axios.delete(
      `/api/v1/collections/${collectionId}/websites/${websiteId}`
    )
  ).data
}

// export async function version() {
//     return await axios.get('/api/v1/version')
// }

// export async function deleteAccount(password) {
//     return await axios.delete('/api/v1/account', { data: { password } })
// }
