import { t } from 'react-i18nify'
import './Plans.scss'

export function Plans() {
    return (
        <div className="pricing">
            <h2>Select Plan</h2>
            <div className="pricing-table">
                <div className='pricing-table-item'>
                    <h3>Free</h3>
                    <div className='pricing-table-item-content'>
                        {t('5 websites')}
                    </div>
                    <div className='pricing-table-item-price'>
                        <span>{t('0 €')}</span>
                        <span>{t('initial')}</span>
                    </div>
                    <div className='pricing-table-item-cta'>
                        <button className='button no-color'>{t('Select')}</button>
                    </div>
                </div>
                <div className='pricing-table-item'>
                    <h3>Starter</h3>
                    <div className='pricing-table-item-content'>
                        {t('200 websites')}
                    </div>
                    <div className='pricing-table-item-price'>
                        <span>{t('5 €')}</span>
                        <span>{t('per Month')}</span>
                    </div>
                    <div className='pricing-table-item-cta'>
                        <button className='button'>{t('Select')}</button>
                    </div>
                </div>
            </div>
        </div>
    )
}
