import { useEffect, useRef, useState } from 'react'
import './ArticleSearch.scss'
import { searchQueryState } from '../states/searchQueryState'
import debounce from 'lodash.debounce'
import { useAtom } from 'jotai'
import { useLocation, useNavigate } from 'react-router-dom'
import { useDebounce } from 'usehooks-ts'
import OutsideClickHandler from 'react-outside-click-handler'

export function ArticleSearch() {
  const [prevSearch, setPrevSearch] = useState(null)
  const [active, setActive] = useState(null)
  const inputRef = useRef(null)
  const location = useLocation()
  const navigate = useNavigate()
  const [searchQuery, setSearchQuery] = useAtom(searchQueryState)
  const [searchQueryText, setSearchQueryText] = useState(null)
  const debouncedSearchTerm = useDebounce(searchQueryText, 300)

  useEffect(() => {
    if (searchQuery?.trim() !== '') {
      setActive(!!prevSearch)
      if (!prevSearch) {
        setSearchQuery(null)
      }
    }
  }, [prevSearch])

  useEffect(() => {
    setPrevSearch(searchQueryText)
    setSearchQuery(searchQueryText)
  }, [debouncedSearchTerm])

  function handleSearchInput(ev) {
    let params = new URLSearchParams({ q: ev.target.value })
    navigate(`${location.pathname}?${params.toString()}`, {
      replace: true,
    })
  }

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    if (params.get('q') !== null) {
      setSearchQueryText(params.get('q'))
    }
  }, [location])

  useEffect(() => {
    let sq = searchQuery

    if (sq === ':focus:') {
      setActive(true)
      if (inputRef?.current) {
        inputRef.current.value = ''
      }
      return
    } else if (sq === null || sq === ':blur:') {
      setActive(false)
      return
    }

    if (sq === '') {
      inputRef?.current?.focus()
    }
    
    if (sq === null) {
      setActive(false)
      return
    } else {
      if (inputRef?.current) {
        inputRef.current.value = sq
      }
    }
    setActive(true)
  }, [searchQuery, inputRef])

  return (
    <OutsideClickHandler onOutsideClick={() => setActive(false)}>
      <div
        id="article-search"
        className={['article-search', active ? 'active' : null]
          .filter((v) => !!v)
          .join(' ')}
        onMouseMove={() => {
          setActive(true)
        }}
      >
        <form className="form" onSubmit={(ev) => ev.preventDefault()}>
          <fieldset>
            <input
              type="search"
              placeholder="Search"
              focus={active ? 'true' : undefined}
              onChange={handleSearchInput}
              ref={inputRef}
            ></input>
          </fieldset>
        </form>
      </div>
    </OutsideClickHandler>
  )
}
