{
  "nl": {
    "sitestash": "sitestash",
    "Please enter a URL": "Voer een URL in",
    "Some problem occured while adding site": "Er is een probleem opgetreden bij het toevoegen van de site",
    "Please ensure that you have access to the site": "Zorg ervoor dat je toegang hebt tot de site",
    "In most cases that means that you need to be logged in on the website you want to stash": "In de meeste gevallen betekent dit dat je ingelogd moet zijn op de website die je wilt opslaan.",
    "Then try again to the save the website with the browser plugin": "Probeer dan opnieuw de website op te slaan met de browserplugin",
    "Add Website": "Website toevoegen",
    "Click here to show all options": "Klik hier om alle opties weer te geven",
    "Or press ctrl / ⌘": "Of druk op ctrl / ⌘",
    "Content classification": "Inhoud classificatie",
    "Target language": "Doeltaal",
    "Keep original": "Bewaar origineel",
    "Add to collection": "Toevoegen aan collectie",
    "Auto": "Auto",
    "Summarize mode": "Modus Samenvatten",
    "The website is behind a paywall - or not accessible by third parties": "De website staat achter een paywall - of is niet toegankelijk voor derden",
    "Hint: It might be easier to use the <a href='https://www_sitestash_net/browser-extension' target='blank'>sitestash-browser-plugin</a> instead of inserting html code manually": "Tip: Het is misschien gemakkelijker om de <a href='https://www.sitestash.net/browser-extension' target='blank'>sitestash-browser-plugin</a> te gebruiken in plaats van handmatig html-code in te voegen.",
    "Insert the complete html of the page here, so that we can analyze the content without accessing the website": "Voeg hier de volledige html van de pagina in, zodat we de inhoud kunnen analyseren zonder de website te openen",
    "${website_process_status}": "${website.process_status}",
    "Title could not be detected": "Titel kon niet worden gedetecteerd",
    "Read more": "Meer lezen",
    "Name of the collection": "Naam van de collectie",
    "Add collection": "Collectie toevoegen",
    "Websites": "Websites",
    "Sure?": "Zeker weten?",
    "delete": "verwijderen",
    "No websites stashed, yet": "Nog geen websites verborgen",
    "Start adding websites easily by using the browser plugin": "Eenvoudig websites toevoegen met de browser-plugin",
    "or add a website manually here": "of voeg hier handmatig een website toe",
    "Could not login": "Kan niet inloggen",
    "Could not login_ Wrong Password?": "Kan niet inloggen. Verkeerd wachtwoord?",
    "E-Mail": "E-mail",
    "Password": "Wachtwoord",
    "Your login code": "Uw inlogcode",
    "If you have signed up correctly, you have now received a login code which you can place here": "Als je je correct hebt aangemeld, heb je nu een inlogcode ontvangen die je hier kunt plaatsen",
    "We sent the login code": "We hebben de inlogcode verzonden",
    "Click here, if you want to use your password instead": "Klik hier als je in plaats daarvan je wachtwoord wilt gebruiken",
    "Remember login": "Login onthouden",
    "No Account, yet? Sign up here": "Nog geen account? Meld je hier aan",
    "Theme": "Thema",
    "Collections": "Collecties",
    "Your Profile": "Uw profiel",
    "Search": "Zoek op",
    "Signup": "Aanmelden",
    "Login": "Inloggen",
    "5 websites": "5 websites",
    "0 €": "0 €",
    "initial": "eerste",
    "Select": "Selecteer",
    "200 websites": "200 websites",
    "5 €": "5 €",
    "per Month": "per maand",
    "Your profile": "Jouw profiel",
    "Language": "Taal",
    "Translate articles by default to": "Vertaal artikelen standaard naar",
    "Credits": "Credits",
    "Number of credits left": "Aantal resterende credits",
    "Articles": "Artikelen",
    "Buy more credits": "Meer credits kopen",
    "Log out": "Afmelden",
    "E-mail has already been signed up": "E-mail is al aangemeld",
    "Passwords do not match": "Wachtwoorden komen niet overeen",
    "Could not signup": "Kan niet aanmelden",
    "Confirm password": "Wachtwoord bevestigen",
    "Invitation code": "Code uitnodiging",
    "The summarizer will add extra costs": "De samenvatter brengt extra kosten met zich mee",
    "Translate": "Vertaal",
    "Text is saving…": "Tekst is opslaan...",
    "Save text": "Tekst opslaan",
    "Save custom text": "Aangepaste tekst opslaan",
    "Create professional summarize": "Professioneel samenvatten",
    "Professional Summary": "Professionele samenvatting",
    "Analyze HTML": "HTML analyseren",
    "Delete Article": "Artikel verwijderen",
    "Change Article URL": "Artikel URL wijzigen",
    "Remove custom summary": "Aangepaste samenvatting verwijderen",
    "Save and use custom summary above": "Bovenstaande aangepaste samenvatting opslaan en gebruiken",
    "Change URL": "URL wijzigen",
    "Summarize custom text instead of auto-extracted content": "Aangepaste tekst samenvatten in plaats van automatisch geëxtraheerde inhoud",
    "Helpful if the summary seems to be wrong in regards of context": "Nuttig als de samenvatting niet lijkt te kloppen wat betreft de context",
    "The update of the summary may take a minute or so - so please be patient": "Het bijwerken van de samenvatting kan ongeveer een minuut duren - wees dus geduldig",
    "Summarize custom text": "Aangepaste tekst samenvatten",
    "Share Article": "Artikel delen",
    "Share edited article text": "Bewerkte tekst van artikel delen",
    "Don't share article anymore": "Artikel niet meer delen",
    "Copied": "Gekopieerd",
    "Copy Share URL to Clipboard": "URL delen naar klembord kopiëren",
    "Make this article public to share it with others": "Maak dit artikel openbaar om het met anderen te delen",
    "Share article": "Artikel delen",
    "Remove from favourites": "Verwijderen uit favorieten",
    "Add to favourites": "Toevoegen aan favorieten",
    "Star": "Ster",
    "There was a problem while processing the url": "Er is een probleem opgetreden tijdens het verwerken van de url",
    "Maybe not all parts of the website are accessible or have relevant content? If the website is behind a paywall which you can access, you should try again with the browser plugin_": "Misschien zijn niet alle delen van de website toegankelijk of hebben ze relevante inhoud? Als de website zich achter een betaalmuur bevindt waar je wel toegang tot hebt, moet je het opnieuw proberen met de browserplugin.",
    "Alternatively you can read the article anyway, by clicking here": "Je kunt het artikel ook lezen door hier te klikken",
    "Note": "Opmerking",
    "The original text was too long_ So parts from the end of the text are not included in the summary and may corrupt the correct gist_": "De oorspronkelijke tekst was te lang. Delen aan het einde van de tekst zijn dus niet opgenomen in de samenvatting en kunnen de juiste essentie bederven.",
    "Source: ": "Bron: ",
    "Key moments": "Belangrijke momenten",
    "Hide key moments": "Belangrijke momenten verbergen",
    "Show image": "Afbeelding tonen",
    "Add image by url": "Afbeelding toevoegen via url",
    "Save": "Sla",
    "Hide original content": "Verberg originele inhoud",
    "Show original content": "Toon originele inhoud",
    "Original URL: ": "Oorspronkelijke URL: ",
    "Website is ready to read here in a moment": "Website is zo te lezen hier",
    "Website is already stashed": "Website is al opgeborgen",
    "Update Website": "Website bijwerken",
    "Website is behind paywall": "Website zit achter betaalmuur",
    "Content": "Inhoud",
    "Translate to": "Vertalen naar",
    "Please login to use sitestash": "Log in om sitestash te gebruiken",
    "Sign up here for free": "Meld je hier gratis aan",
    "Log In": "Aanmelden",
    "The provided credentials are incorrect": "De opgegeven referenties zijn onjuist",
    "Please confirm your e-mail-address first": "Bevestig eerst je e-mailadres",
    "Your login code:": "Uw inlogcode:",
    "If you did not request a login, no further action is required": "Als u geen login heeft aangevraagd, hoeft u geen verdere actie te ondernemen.",
    "URL not found": "URL niet gevonden",
    "Invalid URL": "Ongeldige URL",
    "URL is probably behind cloudflare protecion": "URL zit waarschijnlijk achter cloudflare-protectie",
    "URL could not be accessed": "URL kon niet worden geopend",
    "Not enough credits": "Niet genoeg credits",
    "Website already stashed": "Website al verstopt",
    "Website is stashed": "Website is verborgen",
    "Not allowed": "Niet toegestaan",
    "No valid invitation code": "Geen geldige uitnodigingscode",
    "E-Mail already exists": "E-mail bestaat al",
    "Password must be different than email": "Wachtwoord moet anders zijn dan e-mail",
    "Signup created - Please confirm your e-mail before logging in": "Aanmelden gemaakt - Bevestig je e-mail voordat je inlogt",
    "Language not supported": "Taal niet ondersteund",
    "Resource not found": "Bron niet gevonden",
    "The password did not match": "Het wachtwoord kwam niet overeen",
    "Fast": "Snel",
    "Accurate": "Nauwkeurig",
    "Large content": "Grote inhoud",
    "Large content and accurate summary": "Grote inhoud en nauwkeurige samenvatting",
    "If you have signed up correctly, you have now received a login code": "Als je je correct hebt aangemeld, heb je nu een inlogcode ontvangen",
    "Too many login attempts": "Te veel aanmeldpogingen",
    "Code or e-mail is invalid": "Code of e-mail is ongeldig"
  }
}