import './App.scss'

import {
  BrowserRouter,
  Link,
  Route,
  Routes,
  Navigate,
  useLocation,
} from 'react-router-dom'

import * as api from '../lib/api'

import { currentUserState } from './states/currentUserState'

import '../i18n/i18n'
import { t } from '../lib/helper'

import { useEffect, useState } from 'react'
import { Navigation } from './components/Navigation'
import { Signup } from './components/Signup'
import { Add } from './components/Add'
import { Login } from './components/Login'
import { Home } from './components/Home'
import { Logout } from './components/Logout'
import { WebsiteArticle } from './components/WebsiteArticle'
import { Profile } from './components/Profile'
import { useAtom } from 'jotai'
import { LoremIpsum } from './components/LoremIpsum'
import { NotFound } from './components/NotFound'
import { Plans } from './components/Plans'
import { setLocale } from 'react-i18nify'
import ProgressBar from 'react-scroll-progress-bar'
import { Collections } from './components/Collections'
import { BrowserRouter } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'

import 'react-toastify/dist/ReactToastify.css'

export function App() {
  const [user, setUser] = useAtom(currentUserState)
  const [userIsNotLoggedIn, setUserIsNotLoggedIn] = useState(null)
  const [showProgressBar, setShowProgressBar] = useState(false)
  const [actionMenu, setActionMenu] = useState(null)
  const location = useLocation()

  useEffect(() => {
    document.querySelector('body').dataset.theme =
      localStorage.getItem('theme') || 'dark'
  }, [currentUserState])

  useEffect(() => {
    // setActionMenu(null)
    if (!/^\/websites\/\d/.test(location.pathname)) {
      let body = document.querySelector('body')
      body.dataset.theme = localStorage.getItem('theme')
      body.style.removeProperty('--background-image-url')
      body.classList.remove('with-background-image')
      setShowProgressBar(false)
    } else {
      // on show article
      setShowProgressBar(true)
    }
  }, [location])

  useEffect(() => {
    async function loginUser(token) {
      api.setBearerAuthToken(token, localStorage)
      let user = null
      try {
        user = await api.user()
      } catch (e) {
        if (e.response?.status === 401) {
          console.debug(
            `Session invalid. Clearing authtoken from local storage now`
          )
          localStorage.removeItem('authToken')
          setUserIsNotLoggedIn(true)
          return
        } else {
          throw e
        }
      }
      setUser(user)
      setUserIsNotLoggedIn(false)
      // setLocale(translations[lang] ? lang : 'en')
      setLocale(user.language)
      console.debug(`Logged in as ${user.email}`)
    }

    async function fetchUser() {
      if (!user.email) {
        let token = new URLSearchParams(window.location.search).get('authToken')

        // we have a authToken from a signup session
        if (token && /laravel_sanctum/.test(token)) {
          loginUser(token)
          return
        }

        token = await api.getAuthToken()

        if (token) {
          loginUser(token)
          return
        } else {
        }
        setUserIsNotLoggedIn(true)
        console.info('User is not logged in. Send to login')

        if (window.location.pathname !== '/login') {
          window.location.href = '/login'
        }
      }
    }
    fetchUser()
  }, [user])

  return (
    <>
      {showProgressBar && (
        <div className="scroll-progress-bar">
          <ProgressBar height="2px" bgcolor="#7f00ff" duration="0.2" />
        </div>
      )}
      <ToastContainer />
      <div id="app">
        <Navigation actionMenu={actionMenu}></Navigation>
        <div id="main">
          <Routes>
            <Route path="/about" element={<Navigate to="/signup" />} />
            <Route path="/lorem" element={<LoremIpsum></LoremIpsum>} />
            <Route path="/plans" element={<Plans></Plans>} />
            {user.email ? (
              <>
                <Route path="/login" element={<Navigate replace to="/" />} />
                <Route path="/signup" element={<Navigate replace to="/" />} />
                <Route
                  path="/"
                  element={<Home setActionMenu={setActionMenu}></Home>}
                />
                <Route
                  path="/profile"
                  element={<Profile setActionMenu={setActionMenu}></Profile>}
                />
                <Route
                  path="/collections"
                  element={
                    <Collections setActionMenu={setActionMenu}></Collections>
                  }
                />
                <Route path="/logout" element={<Logout></Logout>} />
                <Route
                  path="/websites/:websiteID"
                  element={<WebsiteArticle setActionMenu={setActionMenu} />}
                />
                <Route
                  path="/add"
                  element={<Add setActionMenu={setActionMenu}></Add>}
                />
                <Route path="*" element={<NotFound></NotFound>}></Route>
              </>
            ) : (
              <>
                <Route
                  path="/"
                  element={
                    userIsNotLoggedIn === true ? (
                      <div className="welcome-screen">
                        <h1>{t('sitestash')}</h1>
                        <p>
                          <Link to="/login">Login here</Link>
                          <br></br>
                          <Link to="/signup" className="button signup">
                            Signup here
                          </Link>
                        </p>
                      </div>
                    ) : (
                      <Navigate to="/" />
                    )
                  }
                />
                <Route path="/signup" element={<Signup></Signup>} />
                <Route path="/login" element={<Login></Login>} />
              </>
            )}
          </Routes>
        </div>
      </div>
    </>
  )
}
