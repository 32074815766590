import { translate } from 'react-i18nify'
import parse from 'html-react-parser'
import { differenceInDays, differenceInMinutes, formatDistance } from 'date-fns'
import md5 from 'js-md5'
import * as dateLocales from 'date-fns/locale'
import { extractArticleFromHTML } from './extract_article_from_html.mjs'

export { parse, md5, extractArticleFromHTML }

export function t(key, replacements = {}, options = {}) {
  key = key.replace(/\./g, '_')
  // replacements = { ...replacements, ...{ dot: '.' } }
  return translate(key, replacements, options)
}

// export function newLineAfterEachSentence(text) {
//   return text.replace(/([.?!]) /g, '$1\n\n')
// }

export function parseWithBR(text) {
  var str = text
  var p = document.createElement('p')
  p.textContent = str
  var converted = p.innerHTML
  return parse(converted.replace(/\n+/g, '<br><br>'))
}

export function wordcount(s) {
  return s.replace(/-/g, ' ').trim().split(/\s+/g).length
}

export function stripTags(html) {
  let div = document.createElement('div')
  div.innerHTML = html
  return div.textContent || div.innerText || ''
}

export function stripTagsFromString(str) {
  return str.replace(/(<([^>]+)>)/gi, '')
}

export function dateStringToLocaleDate(dateString, locale = null) {
  return new Date(dateString).toLocaleDateString(locale || undefined)
}

export function dateStringToHumanDate(
  dateString,
  locale = null,
  fallbackDate = null
) {
  if (!dateString) {
    return null
  }
  let daysAgo = differenceInDays(new Date(dateString), new Date())
  if (
    differenceInMinutes(new Date(dateString), new Date()) > 0 &&
    fallbackDate
  ) {
    // date can not be in future (TODO: check TimeZones!)
    return dateStringToHumanDate(fallbackDate, locale)
  }
  // if (daysAgo >= -1 && dateString.match(/\d{2}:\d{2}:\d{2}/)) {
  //   return formatDistance(new Date(dateString), new Date(), { addSuffix: true }) + ' ' + format(new Date(dateString), 'H:mm');
  // } else
  if (daysAgo > -30) {
    return formatDistance(new Date(dateString), new Date(), {
      addSuffix: true,
      locale: dateLocales[locale || 'en'],
    })
  } else {
    return dateStringToLocaleDate(dateString, locale)
  }
}

// https://developer.mozilla.org/en-US/docs/Web/API/URL/canParse_static
export function canParseUrl(url) {
  let urlString = String(url)
  try {
    return !!new URL(urlString)
  } catch (error) {
    return false
  }
}

export function getImageBrightness(image, callback) {
  // let imageSrc = image.getAttribute('src')
  var img = image.cloneNode(true) //document.createElement('img')
  img.crossOrigin = 'Anonymous'

  // img.src = imageSrc
  img.style.display = 'none'
  document.body.appendChild(img)

  var colorSum = 0

  function onLoad() {
    // create canvas
    var canvas = document.createElement('canvas')
    canvas.width = this.width
    canvas.height = this.height

    var ctx = canvas.getContext('2d')
    ctx.drawImage(this, 0, 0)

    var imageData = ctx.getImageData(0, 0, canvas.width, canvas.height)
    var data = imageData.data
    var r, g, b, avg

    for (var x = 0, len = data.length; x < len; x += 4) {
      r = data[x]
      g = data[x + 1]
      b = data[x + 2]

      avg = Math.floor((r + g + b) / 3)
      colorSum += avg
    }
    img.remove()
    var brightness = Math.floor(colorSum / (this.width * this.height))
    callback(brightness)
  }

  img.onload = onLoad
}

export function _getImageBrightness(image, callback) {
  // var thisImgID = image.getAttribute('id')

  var img = image.cloneNode(true) //document.createElement('img')

  img.style.display = 'none'
  img.crossOrigin = 'Anonymous'
  document.body.appendChild(img)

  var colorSum = 0

  function onLoad(img) {
    // create canvas
    var canvas = document.createElement('canvas')
    canvas.width = img.width
    canvas.height = img.height

    var ctx = canvas.getContext('2d')
    ctx.drawImage(img, 0, 0)

    var imageData = ctx.getImageData(0, 0, canvas.width, canvas.height)
    var data = imageData.data
    var r, g, b, avg

    for (var x = 0, len = data.length; x < len; x += 4) {
      r = data[x]
      g = data[x + 1]
      b = data[x + 2]

      avg = Math.floor((r + g + b) / 3)
      colorSum += avg
    }

    var brightness = Math.floor(colorSum / (img.width * img.height))
    callback(brightness)
  }

  onLoad(img)
}

export function isImageBright(imageElement) {
  const canvas = document.createElement('canvas')
  const context = canvas.getContext('2d')
  const width = imageElement.naturalWidth
  const height = imageElement.naturalHeight
  canvas.width = width
  canvas.height = height
  imageElement.crossOrigin = 'Anonymous'

  context.drawImage(imageElement, 0, 0, width, height)
  const imageData = context.getImageData(0, 0, width, height)
  const data = imageData.data
  let brightness = 0
  for (let i = 0; i < data.length; i += 4) {
    brightness += 0.299 * data[i] + 0.587 * data[i + 1] + 0.114 * data[i + 2]
  }
  brightness = brightness / (width * height)
  return brightness >= 128
}

export function isTouchDevice() {
  return (
    'ontouchstart' in window ||
    navigator.maxTouchPoints > 0 ||
    navigator.msMaxTouchPoints > 0
  )
}

export function makeTextPlaceholderBlocks(min, max, lines = 1) {
  let linesList = []
  if (min.constructor === Array) {
    min.forEach((def) => {
      if (def.constructor === Array) {
        min = def[0]
        max = def[1] || def[0]
      } else {
        min = max = def
      }
      linesList.push(
        Array(Math.floor(Math.random() * (max - min + 1) + min)).join('█')
      )
    })
    return linesList
  }

  for (let i = 0; i < lines; i++) {
    linesList.push(
      Array(Math.floor(Math.random() * (max - min + 1) + min)).join('█')
    )
  }
  return linesList
}

export function trimHeadline(headline, maxLength = 30, suffix = ' …') {
  if (!headline || headline.trim().replace(/\s+/g, '').length < maxLength) {
    return headline
  }
  let parts = headline.match(/^(.+?)([\:\-\|]\s+)(.*)$/)
  if (!parts) {
    return headline
  }
  if (parts && parts[1].split(/\s+/).length > 1) {
    return parts[1].trim() + suffix
  }
  return trimHeadline(parts[3]?.trim(), maxLength, suffix)
}
